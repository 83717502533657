import React from "react";
import { Link } from "gatsby"

import styles from './Demo.module.css'

const Demo = ({ primary_tag }) => {
    const handleClick = () => {
        if (typeof window !== 'undefined') {
            window.location = primary_tag.description
        }
    }

    return (
        <div className={styles.container}>
            <h1 className={styles.tryItNow}>Try it now</h1>
            <p className={styles.subText}>Free and anonymous demo</p>
            <p className={styles.description}>{primary_tag.meta_description}</p>
            <button className={styles.button} onClick={handleClick}>Launch Demo</button>
            <p className={styles.information}>All our demos permanently delete any details shared, including activity logs. We keep no record of your personal details.</p>
        </div>
    );
};

export default Demo;
