import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";
import Demo from "../components/common/Demo";
import Author from "../components/common/Author";
import YotiApp from "../components/common/YotiApp";

import styles from "./post.module.css";
import { forEachRight } from "lodash";

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ data, location }) => {
    const post = data.ghostPost;
    let displayApp = false;
    post.tags.forEach((tag) => {
        if (tag.slug === "need-yoti") {
            displayApp = true;
        }
    });

    const sortedProducts =
        post.tags && post.tags.sort((a, b) => (a.name < b.name ? -1 : 1));

    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <div className="container">
                    <article className="content">
                        {post.feature_image ? (
                            <figure className="post-feature-image">
                                <img
                                    src={post.feature_image}
                                    alt={post.title}
                                />
                            </figure>
                        ) : null}
                        <section className="post-full-content">
                            <div className={styles.contentGrid}>
                                <div className="content-left">
                                    <h1 className="content-title">
                                        {post.title}
                                    </h1>
                                    {sortedProducts && (
                                        <div className={styles.products}>
                                            <p className={styles.productsInUse}>
                                                Products in use: <br />
                                            </p>
                                            {sortedProducts.map(
                                                (tag) =>
                                                    tag.feature_image && (
                                                        <p
                                                            className={
                                                                styles.productName
                                                            }
                                                        >
                                                            <img
                                                                key={tag.name}
                                                                className={
                                                                    styles.productImage
                                                                }
                                                                src={
                                                                    tag.feature_image
                                                                }
                                                                alt={tag.name}
                                                            />{" "}
                                                            {tag.name}
                                                        </p>
                                                    )
                                            )}
                                        </div>
                                    )}
                                    <div
                                        className={styles.stickyContainer}
                                    ></div>
                                    <div className={styles.mobileDemo}>
                                        <Demo {...post} />
                                    </div>
                                    {displayApp ? <YotiApp /> : null}
                                    {/* The main post content */}
                                    <section
                                        className="content-body load-external-scripts"
                                        dangerouslySetInnerHTML={{
                                            __html: post.html,
                                        }}
                                    />
                                    <div className={styles.mobileFooter}>
                                        <Demo {...post} />
                                    </div>
                                    <div className={styles.mobileFooter}>
                                        <Author {...post} />
                                    </div>
                                </div>

                                <div className={styles.contentRight}>
                                    <div className={styles.sticky}>
                                        <Demo {...post} />
                                        <Author {...post} />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </article>
                    <section className={styles.discover}>
                        <p className="site-banner-sub-desc">
                            Discover ways people can use our products.
                        </p>
                        <img
                            src="/assets/images/icons/ico-arrow-down.png"
                            alt="Arrow Down"
                        ></img>
                    </section>
                </div>
            </Layout>
        </>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Post;

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`;
