import React from "react";
import styles from "./Author.module.css";

const Author = ({ primary_author }) => {
    let authorEmail = "business@yoti.com";
    if (primary_author.bio !== null) {
        authorEmail = primary_author.bio;
    }
    return (
        <div className={styles.container}>
            <h1 className={styles.findOutMore}>Find out more</h1>
            <p className={styles.description}>
                For more information on how Yoti might be used for your
                business, please contact us{" "}
                <strong>
                    <a
                        href={`https://www.yoti.com/contact/business/`}
                        target="_blank"
                    >
                        here
                    </a>
                </strong>
            </p>
        </div>
    );
};

export default Author;
