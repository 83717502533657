import React from "react";

import styles from "./YotiApp.module.css";

const IMAGES = {
    GOOGLE_PLAY: "/assets/images/icons/google-play-badge.svg",
    APP_STORE: "/assets/images/icons/app-store-badge.svg",
    YOTI_ICON: "/assets/images/icons/ico-yoti-icon.svg",
};

const STORE = {
    ANDROID:
        "https://play.google.com/store/apps/details?id=com.yoti.mobile.android.live&hl=en_GB",
    IOS: "https://apps.apple.com/gb/app/yoti-your-digital-identity/id983980808",
};

const YotiApp = () => {
    return (
        <div className={styles.box}>
            <h2 className={styles.header}>
                Part of this demo requires the Yoti App
            </h2>
            <p className={styles.description}>
                You will need to register the Yoti app on your smartphone to be
                able to use Yoti services in this demo.
            </p>
            <div className={styles.stores}>
                <a href={STORE.IOS}>
                    <img
                        className={styles.appStore}
                        src={IMAGES.APP_STORE}
                    ></img>
                </a>
                <a href={STORE.ANDROID}>
                    <img
                        className={styles.appStore}
                        src={IMAGES.GOOGLE_PLAY}
                    ></img>
                </a>
            </div>
        </div>
    );
};

export default YotiApp;
